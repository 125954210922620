<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <v-col class="justify-start" sm="4" md="3" lg="2" xl="2">
          <FormButton text="Voltar" type="default" full @click="onGoBack" />
        </v-col>
        <v-col sm="4" md="3" lg="2" xl="2">
          <FormButton text="Novo usuário" type="primario" full @click="onNew" />
        </v-col>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :sortDesc="true"
        ref="datatable"
        sortBy="name"
        search
      >
        <template v-slot:data_criacao="{ col }">
          {{ col | formatDateTime(col) }}
        </template>
        <template v-slot:idusuario="{ row }">
          <FormButtonMenu
            icon
            :options="getTableOptions()"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import Panel from '@/components/Panel'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import { CLIENTE_LOAD, USUARIO_REMOVE } from '@/store/actions/cliente'
const ORIGEM_TYPE = { DADOS_RECEITA: 'DADOS_RECEITA' }

export default {
  name: 'ClienteUsuarios',
  components: { DataTable, Panel, FormButton, FormButtonMenu },
  data: () => ({
    headers: [
      { text: 'Nome', align: 'center', value: 'nome' },
      {
        text: 'E-mail',
        align: 'center',
        value: 'email'
      },
      {
        text: 'Data de criação',
        align: 'center',
        value: 'data_criacao'
      },
      {
        text: 'Ações',
        value: 'idusuario',
        align: 'center',
        clickable: false
      }
    ],
    cliente: null,
    addressLength: null
  }),
  created() {
    let idcliente = this.$route.params.idclient
    if (!idcliente) {
      this.$router.replace('/fornecedor/cadastro/cliente')
    } else {
      this.$store
        .dispatch(CLIENTE_LOAD, { idcliente })
        .then(cliente => {
          this.cliente = cliente
          this.reloadDatatable()
        })
        .catch(() => {
          this.$router.replace('/fornecedor/cadastro/cliente')
        })
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpoint() {
      const idcadastroextracliente = _.get(
        this.cliente,
        'idcadastroextracliente',
        null
      )

      if (!idcadastroextracliente || !this.getFornecedorId) return null
      return `/api/v1/app/${idcadastroextracliente}/usuario/${this.getFornecedorId}/usuarios`
    },
    clientName() {
      return _.get(this.cliente, 'name') || ''
    }
  },
  methods: {
    getTableOptions() {
      const opt = []
      opt.push({ text: 'Excluir', value: 'delete' })
      return opt
    },
    getOrigemColor(col) {
      switch (col) {
        case ORIGEM_TYPE.DADOS_RECEITA:
          return 'auxiliar'
        default:
          return 'informacao'
      }
    },
    getTipoColor(col) {
      switch (col) {
        case 'ENTREGA':
          return 'primario'
        default:
          return 'purple'
      }
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onGoBack() {
      this.$router.replace('/fornecedor/cadastro/cliente')
    },
    onNew() {
      const idcadastroextracliente = _.get(
        this.cliente,
        'idcadastroextracliente',
        null
      )

      this.$router.push(
        `usuario/novo/${idcadastroextracliente}/${this.getFornecedorId}`
      )
    },
    onClose() {
      this.$refs.datatable && this.reloadDatatable()
    },
    reloadDatatable() {
      this.$refs.datatable.reload()

      setTimeout(() => {
        this.addressLength = parseInt(this.$refs.datatable.options.itemsLength)
      }, 500)
    },
    onDelete(item) {
      const options = {
        title: `Remover o vínculo com o usuário?`,
        text: 'Essa ação é irreversível'
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      const idcadastroextracliente = _.get(
        this.cliente,
        'idcadastroextracliente',
        null
      )

      this.$store
        .dispatch(USUARIO_REMOVE, {
          idusuario: item.id_usuario,
          idcadastroextracliente,
          idfornecedor: this.getFornecedorId
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Usuário removido com sucesso')
          this.reloadDatatable()
        })
    }
  }
}
</script>
